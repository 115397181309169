export default {
  path: '/quantity',
  name: 'quantity',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'test_param_info',
      name: 'test_param_info',
      meta: { title: '参数管理', permission: 'test_param_info' },
      component: () => import('@/views/quantity/test_param_info/index'),
    },

    {
      path: 'standard',
      name: 'standard',
      meta: { title: '测试规范', permission: 'standard' },
      component: () => import('@/views/quantity/standard/index'),
    },
    {
      path: 'pending_test_p',
      name: 'pending_test_p',
      meta: { title: '产品待检测', permission: 'pending_test_p' },
      component: () => import('@/views/quantity/pending_test_p/index'),
    },
    {
      path: 'pending_test_m',
      name: 'pending_test_m',
      meta: { title: '物料待检测', permission: 'pending_test_m' },
      component: () => import('@/views/quantity/pending_test_m/index'),
    },

    {
      path: 'material_test_record',
      name: 'MaterialTestRecordFormPage',
      meta: { title: '物料检测记录', permission: 'material_test_record' },
      component: () => import('@/views/quantity/material_test_record_and_results/index'),
    },

    
    {
      path: 'good_test_record',
      name: 'GoodTestRecordFormPage',
      meta: { title: '产品检测记录', permission: 'good_test_record' },
      component: () => import('@/views/quantity/good_test_record_and_results/index'),
    },


    {
      path: 'test_log_p',
      name: 'test_log_p',
      meta: { title: '产品检测记录', permission: 'test_log_p' },
      component: () => import('@/views/quantity/test_log_p/index'),
    },
    {
      path: 'test_log_m',
      name: 'test_log_m',
      meta: { title: '物料检测记录', permission: 'test_log_m' },
      component: () => import('@/views/quantity/test_log_m/index'),
    },
    
  ],
}