export default {
  path: '/shipping',
  name: 'shipping',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'pre_boxing',
      name: 'pre_boxing',
      meta: { title: '预分箱', permission: 'pre_boxing' },
      component: () => import('@/views/shipping/pre_boxing/index'),
    },
    {
      path: 'order_shipping',
      name: 'order_shipping',
      meta: { title: '订单发货', permission: 'order_shipping' },
      component: () => import('@/views/shipping/order_shipping/index'),
    },
    {
      path: 'shipping_record',
      name: 'shipping_record',
      meta: { title: '发货记录', permission: 'shipping_record' },
      component: () => import('@/views/shipping/shipping_record/index'),
    },
   
  ],
}