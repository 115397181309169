import VueRouter from 'vue-router'
import user from './user'
import account from './account'
import manage from './manage'
import system from './system'
import report from './report'
import basicData from './basicData'
import goods from './goods'
import materials from './materials'
import purchasing from './purchasing'
import sale from './sale'
import warehouse from './warehouse'
import warehousem from './warehousem'
import finance from './finance'
import production from './production'
import hrms from './hrms'
import demo from './demo'
import shipping from './shipping'
import quantity from './quantity'
const index = {
  path: '/',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/home',
  children: [
    {
      path: '/home',
      name: 'home',
      meta: { title: '首页' },
      component: () => import('@/views/_home/Home'),
    },
  ]
}

const routes = [index, user, account, manage, system, report, basicData, goods, materials,purchasing, sale,
   warehouse,warehousem, finance,production,hrms,shipping,quantity,
  demo
];

export default new VueRouter({ mode: 'history', routes })
